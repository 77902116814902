@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    body {
      @apply bg-[#000511] text-[#eeeeee] tracking-wide
    }
  

  }